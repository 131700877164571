@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,800|Source+Serif+Pro:400,700&display=swap);
/*
@media (max-width: 1200px) {
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
}
 */

 .fas.fa-check {
     font-size: .9rem;
     margin-top: 3px;
     margin-left: -7px;
     position: absolute;
 }
 .pretty-persora-check {
     width: 100%;
     margin-bottom: .9rem;
     font-weight: 100 !important;
 }
 .form-check-input {
   overflow: visible !important;
 }

 .pre-text {
   font-size: .8rem;
 }
 

 html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    background-image: url("/images/bg_persora_form.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -170px;
    background-color: #283A56;
  }
  .hover-tr, .hover-tr a {
      transition: all 200ms ease-in-out;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -ms-transition: all 200ms ease-in-out;
      -o-transition: all 200ms ease-in-out;
  }
  
  a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
  }
  a[href^="tel"]{
      color:inherit;
      text-decoration:none;
  }
  
  .hiddenn	{opacity:0;}
  .visiblee	{opacity:1;}
  
  .delay-100ms {-webkit-animation-delay: 100ms;animation-delay: 100ms;}
  .delay-150ms {-webkit-animation-delay: 150ms;animation-delay: 150ms;}
  .delay-200ms {-webkit-animation-delay: 200ms;animation-delay: 200ms;}
  .delay-250ms {-webkit-animation-delay: 250ms;animation-delay: 250ms;}
  .delay-300ms {-webkit-animation-delay: 300ms;animation-delay: 300ms;}
  .delay-350ms {-webkit-animation-delay: 350ms;animation-delay: 350ms;}
  .delay-400ms {-webkit-animation-delay: 400ms;animation-delay: 400ms;}
  .delay-500ms {-webkit-animation-delay: 500ms;animation-delay: 500ms;}
  .delay-600ms {-webkit-animation-delay: 600ms;animation-delay: 600ms;}
  .delay-700ms {-webkit-animation-delay: 700ms;animation-delay: 700ms;}
  
  
  body {
      font-family: 'Montserrat', sans-serif;
      font-size: 1.1rem;
      line-height: 1.65rem;
      color: #363C3C;
      /*padding-top: 50px;*/
  }
  
  .w-300 {font-weight: 300;}
  .w-400 {font-weight: 400;}
  .w-600 {font-weight: 600;}
  .w-700 {font-weight: 700;}
  .w-800 {font-weight: 800;}


  .color-azul-neutro 	{color: #213b59;}
  .color-perfil-a 	{color: #5CBC8E;}
  .color-perfil-p 	{color: #9A91C0;}
  .color-perfil-c 	{color: #F1C176;}
  .color-perfil-e 	{color: #E07358;}
  
  
  .bg-azul-neutro {background: #213b59;}
  .bg-perfil-a 	{background: #5CBC8E;}
  .bg-perfil-p 	{background: #9A91C0;}
  .bg-perfil-c 	{background: #F1C176;}
  .bg-perfil-e 	{background: #E07358;}
  
  h4, .h4 {line-height: 1.95rem;}
  
  .ssp {font-family: 'Source Serif Pro',serif;}
  
  .ms {font-family: 'Montserrat',sans-serif;}
  
  .small-sem-ls {
      font-size: .8rem;
  }
  
  .small-com-ls {
      font-size: .8rem;
      letter-spacing: .1rem;
  }
  
  .wrapper {
    padding: 0 15px;
  }

  p.lead-1 {
	font-size: 1.55rem;
	line-height: 2rem;
}
p.lead-2 {
	font-size: 1.85rem;
	line-height: 2.2rem;
}

@media (max-width: 576px) {
  p.lead-2 {
    font-size: 1.45rem;
    line-height: 2rem;
  }
}


  
 /* header#main-header {
    height: 60px;
    background: #da552f;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
} */


  header#main-header {
      height: 60px;
      background: #fff;
      width: 100%;
      color: #555;
      display: flex;
      align-items: left;
      padding-top: 16px;
  }
  
  .position-content-react {
      padding-top: 40px;
      padding-bottom: 15px;
  }


  @media (max-width: 576px) {
    .position-content-react {
      padding-top: 0;
    }
    body {
      background-position-y: 00px;
    }
  }

  /* Nav Lateral */
.menu-fixa-inicio 	{ -webkit-transition: 600ms; transition: 600ms; }
.menu-stick-hack	{ padding-top: 100px; }

.menu-lateral-relatorio a.nav-link{
	color: #ffffff !important;
	padding: 0;
	border-right: 4px solid #fabf6900;
	margin-bottom: 6px;
	padding-right: 17px;
	margin-right: -17px;
	transition: all 200ms ease-in-out;
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
}
/*.menu-lateral-relatorio a.nav-link:hover{
	border-right: 4px solid #fabf69;
}*/

.menu-lateral-relatorio a.nav-link.passed{
	color: #ffffff55 !important;
}

.menu-lateral-relatorio a.nav-link.active{
	color: #fdd9a9 !important;
	font-weight: 700 !important;
	border-right: 4px solid #fabf69;
}



.col-content-style {
	background-color: #fff;
	border-radius: 15px;
	padding: 32px 28px;
	/*font-family: "Source Serif Pro", serif;*/
	font-size: 1.2rem;;
}

.ul-orientaçoes-mapeamento {
    list-style: none;
    padding: 0;
}

.ul-orientaçoes-mapeamento li {
    font-size: 1.05rem;
    line-height: 1.35rem;
    margin-bottom: .65rem;
}


.btn-persora1 {
    color: #213b59;
    background-color: #fff;
    border-color: #213b59; 
    border-width: 1px;
    border-radius: 20px;
    padding: 10px 20px;}
    .btn-persora1:hover {
      color: #fff;
      background-color: #5CBC8E;
      border-color: #5CBC8E; }
    .btn-persora1:focus, .btn-persora1.focus {
      box-shadow: 0 0 0 0.2rem rgba(111, 82, 255, 0.5); }
    .btn-persora1.disabled, .btn-persora1:disabled {
      color: #fff;
      background-color: #5533ff;
      border-color: #5533ff; }
    .btn-persora1:not(:disabled):not(.disabled):active, .btn-persora1:not(:disabled):not(.disabled).active,
    .show > .btn-persora1.dropdown-toggle {
      color: #fff;
      background-color: #2b00ff;
      border-color: #2800f2; }
      .btn-persora1:not(:disabled):not(.disabled):active:focus, .btn-persora1:not(:disabled):not(.disabled).active:focus,
      .show > .btn-persora1.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(111, 82, 255, 0.5); }


                
.main-form {
  font-size: .8rem;
  }
.main-form label {
  margin-bottom: 15px;
  width: calc(100% - 25px);
}

.main-form input, .main-form select {
  border: 1px solid #213b59;
  background: #fff;
}



.tres-colunas
{
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    padding: 0;
    font-size: 1.05rem;
}

@media (max-width: 991px)
{
    .tres-colunas
    {
        font-size: .85rem;
    }
}


@media (max-width: 576px)
{
    .tres-colunas
    {
        -webkit-column-count: 1 !important;
           -moz-column-count: 1 !important;
                column-count: 1 !important;
        font-size: .9rem;
    }
}


html,
body {
  height: 100%;
}

#root {
  flex: 1 0 auto;
}

#sticky-footer {
  flex-shrink: none;
}

#sticky-footer {
  background-color: #ffffff;
  padding-top: 16px;
  padding-bottom: 15px;
}

.col-footer-ps p {
  display: inline;
}
.col-footer-social .fab {
  font-size: 1.55rem;
  color: #213b59 !important;
  margin-right: 3px;
}
@media (max-width: 991px) {
  .col-footer-ps p {display: block;}
  .align-cols-footer {text-align: left !important;}
  .col-footer-social {margin-top: 20px; padding-bottom: 5px;}
  .col-footer-ps p {margin-bottom: 2px !important;}
  #stick-footer {
    padding: 20px;
  }
}

.labelCultura {
  font-size: .95rem;
  line-height: 1.3rem;
  padding-right: 10%;
  cursor: pointer;
}
h3.ou {
  color: #283A56;
  font-weight: 600;
  font-size: 1.15rem;
  margin-left: 20px;
  margin-top: 9px;
  margin-bottom: 11px;
}
@media (min-width: 768px) {
  .form-check-coluna-esquerda {border-right: 1px dotted #ccc; max-width: 47%; margin-right: 3%;}
}
@media (max-width: 768px) {
  .form-check-coluna-esquerda {border-bottom: 1px dotted #ccc; padding-bottom: 25px; margin-bottom: 25px;}
}

.labelAceite {
  font-size: .95rem;
  line-height: 1.45rem;
  padding-right: 10%;
  cursor: pointer;
}

